export function classNames(
  ...classes: (string | boolean | null | undefined)[]
): string {
  return classes.filter(Boolean).join(" ");
}

// TODO: want to use twMerge like so, but because we have an esoteric tailwind
// config, we need to set it up to know what's a color, size etc.
// See https://twitter.com/shadcn/status/1614692419039105024
// and https://github.com/dcastil/tailwind-merge/blob/v1.9.1/docs/features.md
// export function cn(...inputs: ClassValue[]) {
//   return twMerge(clsx(inputs));
// }
