import KeyboardShortcut from "@library/KeyboardShortcut";
import Link from "next/link";
import React from "react";
import { UrlObject } from "url";
import { classNames } from "./utils/classNames";
import { ConditionalWrapper } from "./utils/wrap";

export type ButtonSize = 24 | 32 | 36 | 40 | 48 | 52;

export interface ButtonProps {
  size?: ButtonSize;
  styling?: "solid" | "outline" | "ghost" | "link" | "bold";
  shade?: "blue" | "gray" | "red" | "yellow" | "green";
  active?: boolean;
  disabled?: boolean;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  tabIndex?: number;
  square?: boolean;

  IconLeft?:
    | React.FunctionComponent<React.ComponentProps<"svg">>
    | React.FunctionComponent<React.ComponentProps<"img">>;
  IconRight?:
    | React.FunctionComponent<React.ComponentProps<"svg">>
    | React.FunctionComponent<React.ComponentProps<"img">>;

  className?: string;

  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  // Disallow focus after click or through tab indexing.
  disableFocus?: boolean;

  // Keyboard just displays the keyboard shortcuts, doesn't make it interactive.
  keyboard?: (string | React.ReactNode)[];

  // When used as a link
  href?: string | UrlObject;
  target?: string;
  rel?: string;

  children?: React.ReactNode;
}

const Button = React.forwardRef(function ButtonComponent(
  props: ButtonProps,
  ref:
    | React.ForwardedRef<HTMLButtonElement>
    | React.ForwardedRef<HTMLAnchorElement>,
) {
  const {
    type = "button",
    styling = "outline",
    size = 32,
    shade = "gray",
    loading,
    disabled,
    href,
    disableFocus,
    square,
    onClick: onClickProp,
    active,
    IconLeft,
    IconRight,
    className,
    ...rest
  } = props;

  // For some UI buttons we don't want to want to allow focus
  // after a mouse click (which is the default behaviour). To do this we
  // use mouseDown events and preventDefault on the event.
  let onClick:
    | React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
    | undefined;
  let onMouseDown:
    | React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
    | undefined;
  if (disableFocus) {
    onClick = (event) => {
      event.preventDefault();
    };
    onMouseDown = (event) => {
      event.preventDefault();
      if (onClickProp) {
        onClickProp(event);
      }
    };
  } else {
    onClick = onClickProp;
    onMouseDown = undefined;
  }

  // TODO: use this https://cva.style/docs/getting-started instead to get typed, easy variants
  const classes = {
    base: "inline-flex justify-center items-center relative select-none group font-bold",
    content: {
      24: "text-12-14 gap-6",
      32: "text-12-14 gap-8",
      36: "text-12-14 gap-8",
      40: "text-14-16 gap-8",
      48: "text-14-14 gap-8",
      52: "text-18-28 gap-16",
    },
    // Applied to all but 'link' buttons
    // The awkward change in padding is to account for the border in outline
    //  buttons so that '40' remains 40 px tall and the width doesn't shift
    padding: {
      24: classNames(
        !square && "rounded-[4px]",
        styling === "outline" ? "py-4 px-[7px]" : "py-[5px] px-8",
      ),
      32: classNames(
        !square && "rounded",
        styling === "outline" ? "py-[7px] px-[11px]" : "py-[8px] px-12",
      ),
      36: classNames(
        !square && "rounded",
        styling === "outline" ? "py-[10px] px-[11px]" : "py-[11px] px-12",
      ),
      40: classNames(
        !square && "rounded ",
        styling === "outline" ? "py-[11px] px-[15px]" : "py-12 px-16",
      ),
      48: classNames(
        !square && "rounded ",
        styling === "outline" ? "py-[13px] px-[13px]" : "py-[14px] px-[14px]",
      ),
      52: classNames(
        !square && "rounded ",
        styling === "outline" ? "py-[11px] px-[19px]" : "py-[12px] px-20",
      ),
    },
    icon: {
      24: "w-12 h-12",
      32: "w-[14px] h-[14px]",
      36: "w-16 h-16 -my-2",
      40: "w-16 h-16 -my-2", // Negative margin otherwise it makes the button too tall
      48: "w-20 h-20",
      52: "w-20 h-20",
    },
    block: "w-full",
    solid: {
      base: "focus:outline-none focus:ring focus:ring-blue-300 ", // applied to all
      normal: "bg-blue-700 text-white hover:bg-blue-800 active:bg-blue-800", // applied if not disabled or active
      disabled: "text-white bg-gray-400",
      active: "bg-blue-800 text-white shadow-inner",
    },
    bold: {
      base: "focus:outline-none focus:ring shadow-e focus:ring-gray-300 ", // applied to all
      normal: "bg-gray-950 text-white hover:bg-black active:bg-gray-800", // applied if not disabled or active
      disabled: "text-white bg-gray-400",
      active: "bg-black text-white shadow-inner",
    },
    outline: {
      base: "border",
      normal: classNames(
        shade === "blue" &&
          "border-blue-400 text-blue-700 hover:text-blue-800 active:bg-blue-200 active:text-blue-700",
        shade === "gray" &&
          "border-gray-200 text-gray-800 hover:border-gray-600 hover:text-gray-900 active:bg-gray-150 active:text-gray-800 dark:hover:border-gray-200 dark:hover:bg-gray-200 dark:hover:text-gray-900",
        // These are less common shades and less thought has been put into the colors
        shade === "green" &&
          "border-green-300 text-green-600 hover:text-green-700 active:bg-green-200 active:text-green-600",
        shade === "yellow" &&
          "border-yellow-300 text-yellow-700 hover:text-yellow-800 active:bg-yellow-200 active:text-yellow-00",
        shade === "red" &&
          "border-red-300 text-red-600 hover:text-red-700 active:bg-red-200 active:text-red-600",
      ),
      disabled: "border-gray-300 text-gray-400",
      active: classNames(
        shade === "blue" && "bg-blue-200 text-blue-700",
        shade === "gray" && "bg-gray-200 text-gray-800",
        shade === "green" && "bg-green-200 text-green-700",
        shade === "yellow" && "bg-yellow-200 text-yellow-700",
        shade === "red" && "bg-red-200 text-red-700",
      ),
    },
    ghost: {
      base: "border-none focus:outline-none focus:ring focus:ring-blue-300 ",
      normal:
        shade === "blue"
          ? "text-blue-700 hover:text-blue-800 active:bg-blue-200 active:text-blue-700"
          : "text-gray-800 hover:text-gray-900 active:bg-gray-200 active:text-gray-800",

      disabled: "text-gray-400",
      // Same as outline
      active:
        shade === "blue"
          ? "bg-blue-200 text-blue-700"
          : "bg-gray-200 text-gray-800",
    },
    // TODO: remove this and use <InlineLink> instead
    link: {
      base: "border-none focus:outline-none !px-0 !py-0",
      normal: "text-text-secondary-3 hover:text-text-secondary-2 ",
      disabled: "text-text-neutral-6",
      active: "hover:text-text-secondary-3",
    },
    cursor: loading
      ? "cursor-progress"
      : disabled
      ? "cursor-not-allowed"
      : "cursor-pointer",
  };

  const Element = href ? "a" : "button";

  return (
    // This is to use Next's Link component when acting as a link to enable
    // Client-side transitions. I don't think there's anything wrong with using
    // this still when the href is for an external link.
    <ConditionalWrapper
      condition={!!href}
      wrapper={(children) => {
        return (
          <Link href={href as UrlObject} passHref legacyBehavior>
            {children}
          </Link>
        );
      }}
    >
      <Element
        // Spread props so this works with RadixUI's asChild.
        {...rest}
        ref={ref as any}
        type={type}
        onClick={onClick}
        onMouseDown={onMouseDown}
        tabIndex={disabled || disableFocus ? -1 : 0}
        // At one point I tried using cn here and everything went really bad.
        className={classNames(
          className,
          classes.base,
          classes.content[size],
          classes.padding[size],
          classes[styling].base,
          disabled
            ? classes[styling].disabled
            : active
            ? classes[styling].active
            : classes[styling].normal,
          classes.cursor,
        )}
        disabled={disabled}
        {...(href
          ? {
              target: props.target,
              rel: props.rel,
            }
          : {})}
      >
        {IconLeft && (
          <IconLeft
            // Neg margin as we've not nailed the height-padding combo
            className={classNames(classes.icon[size], "-my-4 shrink-0")}
          />
        )}
        {props.children}
        {/* TODO: Heads up that the shortcut isn't fully designed/finished and neither is how they should be used with all the buttons */}
        {props.keyboard &&
          props.keyboard?.length > 0 &&
          props.keyboard?.map((key, i) => (
            <KeyboardShortcut
              key={i}
              shade={"blue"}
              className={classNames(i !== 0 && "-ml-6 ", "-my-4")}
              disabled={props.disabled}
            >
              {key}
            </KeyboardShortcut>
          ))}
        {IconRight && (
          <IconRight
            className={classNames(classes.icon[size], "-mx-4 shrink-0")}
          />
        )}
      </Element>
    </ConditionalWrapper>
  );
});

// This was added just to test out the new color variables.
// It's not yet used anywhere, but when we do, make sure it's designed well.
export const InlineLink = ({
  children,
  onClick,
  href,
  target,
  rel,
  disabled,
  active,
}: {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  active?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  href: string;
  target?: string;
  rel?: string;
}) => {
  const classes = {
    base: "border-none focus:outline-none",
    normal: "text-text-secondary-3 hover:text-text-secondary-2 ",
    disabled: "text-text-neutral-6",
    active: "hover:text-text-secondary-3",
  };

  return (
    <Link
      href={href}
      onClick={onClick}
      className={classNames(
        classes.base,
        disabled ? classes.disabled : active ? classes.active : classes.normal,
      )}
      target={target}
      rel={rel}
    >
      {children}
    </Link>
  );
};

export default Button;
