import React from "react";

interface WrapProps {
  condition: boolean;
  wrapper: (children: React.ReactNode) => JSX.Element;
  children: React.ReactNode;
}

export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: WrapProps) => (condition ? wrapper(children) : <>{children}</>);
