import React from "react";
import { isMacOs } from "react-device-detect";
import { CommandIcon } from "./Icons";
import { classNames } from "./utils/classNames";

export interface KeyboardShortcutProps {
  className?: string;
  children: React.ReactNode;
  styling?: "solid" | "outline";
  shade?: "blue" | "gray";
  size?: 16 | 20 | 24;
  disabled?: boolean;
}

const KeyboardShortcut = ({
  className,
  children,
  styling = "solid",
  shade = "gray",
  size = 20,
  disabled = false,
}: KeyboardShortcutProps) => {
  const classes = {
    base: "inline-flex items-center justify-center capitalize select-none rounded-sm font-bold whitespace-nowrap",
    size: {
      16: "h-16 min-w-[16px] px-[3.5px] text-11-12",
      20: "h-20 min-w-[20px] px-4 text-12-12 pt-1 -mt-1", //hacky padding just to make it quicky look on baseline in center. sorry.
      24: "h-24 min-w-[24px] px-6 text-14-14",
    },
    styling: {
      solid: {
        ["gray"]: disabled
          ? "text-gray-600 bg-gray-100"
          : "text-gray-700 bg-gray-200 group-active:bg-gray-300",
        ["blue"]: disabled
          ? "text-blue-300 bg-gray-200"
          : "text-blue-700 bg-blue-300 group-active:bg-blue-200",
      },
      // DECRECATED: This seems to no longer be part of our design library.
      //  Keeping for now as it looks ok and useful to me.
      outline: {
        ["gray"]:
          "text-gray-400 border border-grey-400 group-active:bg-gray-300",
        ["blue"]:
          "text-blue-400 border border-blue-400 group-active:bg-blue-300",
      },
    },
  };

  return (
    <div
      className={classNames(
        classes.base,
        classes.size[size],
        classes.styling[styling][shade],
        className,
      )}
    >
      {children}
    </div>
  );
};

export const ModKey = ({ size }: { size?: 11 | 12 }) =>
  isMacOs ? (
    <CommandIcon
      className={classNames(
        "mx-2 inline-block shrink-0 pb-1",
        size === 11 ? "h-[11px] w-[11px]" : "h-12 w-12",
      )}
    />
  ) : (
    <span>Ctrl</span>
  );

export const ShiftKey = `⇧`;

export const CmdEnterShortcut = () => {
  return (
    <>
      <KeyboardShortcut>
        <ModKey />
      </KeyboardShortcut>{" "}
      +<KeyboardShortcut> Enter</KeyboardShortcut>
    </>
  );
};

export default KeyboardShortcut;
